import React, { useMemo } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { styled } from "../../../stitches.config";
import { Layout } from "../../layout";
import { useAppSelector } from "../../hooks/ReduxHooks";

import { Seo } from "../../components/shared/Seo";

export const Impressum = () => {
  const { t } = useTranslation();
  const { email, primaryNumber, realAddress, realAddressMapsLink } =
    useAppSelector((state) => state.contactInfo);

  return (
    <Layout>
      <Seo title={t("impressum.title")} />
      <BgContainer>
        <Container>
          <PageTitle>{t("impressum.title")}</PageTitle>
        </Container>
      </BgContainer>
      <InfoBox>
        <Text css={{ marginBottom: 12 }} bold={true}>
          taschner.biz GmbH
        </Text>
        <Text>
          <Link
            href={realAddressMapsLink}
            dangerouslySetInnerHTML={{ __html: realAddress }}
          ></Link>
        </Text>
        <br />
        <Text bold={true}>
          Tel.: <Link href={`tel:${primaryNumber}`}>{primaryNumber}</Link>
        </Text>
        <Text bold={true}>
          E-Mail: <Link href={`mailto:${email}`}>{email}</Link>
        </Text>
        <Link href={"www.taschner.biz"}>{"www.taschner.biz"}</Link>
        <Text css={{ margin: "16px 0px" }} bold={true}>
          {t("authorizedManagingDirector") + ":"} <span>Stefan Taschner</span>
        </Text>
        <Text bold={true}>
          {t("registrationCourt") + ":"} <span>{t("courtOfMarburg")}</span>
        </Text>
        <Text bold={true}>
          {t("registrationNumber") + ":"} <span> HRB 5527</span>
        </Text>
        <Text bold={true}>
          {t("salesTax")} <span>{t("salesTax2") + ": DE 264 654 134"}</span>
        </Text>
        <Text bold={true}>
          {t("responsibleForContent")}{" "}
          <span>{t("responsibleForContent2")}</span>
        </Text>
        <Text>{"Stefan Taschner " + t("addressSeeAbove")}</Text>
        <Text css={{ margin: "16px 0px" }}>{t("disclaimer")}</Text>
        <Text>{t("images")}</Text>
      </InfoBox>
    </Layout>
  );
};

const BgContainer = styled("div", {
  background: "$primaryBackground",
});

const Container = styled("div", {
  height: 180,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "0 16px",
  maxWidth: 1280,
  margin: "0 auto",
  "@md": {
    justifyContent: "start",
    padding: 0,
  },
});

const PageTitle = styled("h1", {
  margin: 0,
  color: "$heading",
  fontWeight: 700,
  fontSize: "36px",
  lineHeight: "44px",
  "@md": {
    fontSize: "48px",
    lineHeight: "56px",
  },
});

const InfoBox = styled("div", {
  margin: "48px 16px 80px",
  maxWidth: 1280,
  "@md": {
    margin: "48px auto 80px",
  },
});

const Text = styled("p", {
  margin: 0,
  lineHeight: "1.5rem",
  color: "$text",
  wordWrap: "break-word",
  whiteSpace: "pre-line",
  fontWeight: 500,
  "@md": {
    width: 920,
  },
  variants: {
    bold: {
      true: {
        color: "$text",
        fontWeight: 800,
        "& > span": {
          fontWeight: "500",
        },
      },
    },
  },
});

const Link = styled("a", {
  textDecoration: "none",
  color: "$accent",
  filter: "grayscale(0.5)",
  fontWeight: 500,
  "&:hover, &:focus": {
    filter: "none",
  },
});
